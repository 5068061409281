<template>
  <el-dialog title="活动操作日志" :visible.sync="dialogVisible">
    <el-table
      v-loading.body="tableLoading"
      :data="tableData"
      element-loading-text="加载中 ..."
      border
      header-align="center"
      highlight-current-row
    >
      <el-table-column
        label="操作类型"
        width="200px"
        prop="data_type"
        align="center"
        header-align="center"
      >
        <template slot-scope="scope">
          <span>{{ logType[scope.row.data_type] }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="修改时间"
        width="250px"
        prop="create_time"
        align="center"
        header-align="center"
        show-overflow-tooltip
      />
      <el-table-column label="操作人" prop="creator.name" align="center" header-align="center" />
    </el-table>
    <div style="width: 100%; text-align: center; margin-top: 20px">
      <el-button type="primary" @click="dialogVisible = false">关 闭</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { getOperationLog } from "@/api/activity.js";
export default {
  data() {
    return {
      count: 0,
      dialogVisible: false,
      tableLoading: false,
      tableData: [],
      logType: {
        BASIC: "基本信息",
        CONTENT: "专题页编辑",
        DISPLAY: "专题页编辑",
        UN_DISPLAY: "专题页编辑",
      },
    };
  },
  methods: {
    async showLogs(v) {
      this.dialogVisible = true;
      const res = await getOperationLog(v);
      this.tableData = res.data;
    },
  },
};
</script>

<style></style>
