<template>
  <el-dialog title="抽奖数据统计" :visible.sync="dialogVisible" width="80%" top="2vh">
    <el-descriptions class="margin-top" :column="3" border>
      <el-descriptions-item label-class-name="w150 f14">
        <template slot="label"> 抽奖ID </template>
        {{ prizeInfo.config_id }}
      </el-descriptions-item>
      <el-descriptions-item label-class-name="w150 f14">
        <template slot="label"> 抽奖名称 </template>
        {{ prizeInfo.name }}
      </el-descriptions-item>
    </el-descriptions>
    <el-divider></el-divider>
    <PrizeNumber
      :p-number="prizeInfo.pnumber"
      :draw-number="prizeInfo.draw_number"
      :prize-data="prizeInfo.prize_data"
    />
    <el-divider></el-divider>
    <UserData v-if="dialogVisible" :uuid="uuid" />
  </el-dialog>
</template>

<script>
import { getStatisticalPrizeInfo } from "@/api/luckDraw";
import PrizeNumber from "./PrizeNumber";
import UserData from "./UserData";
export default {
  components: { PrizeNumber, UserData },
  data() {
    return {
      dialogVisible: false,
      uuid: "",
      prizeInfo: "",
    };
  },
  methods: {
    async handleEdit(v) {
      this.uuid = v;
      this.fetchData();
      this.dialogVisible = true;
    },
    async fetchData() {
      const result = await getStatisticalPrizeInfo(this.uuid);
      this.prizeInfo = result.data;
    },
  },
};
</script>

<style lang="less" scoped></style>
