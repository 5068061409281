<template>
  <el-card class="box-card">
    <div slot="header" class="clearfix">
      <span
        >参与人数：<el-tag type="warning">{{ pNumber }}</el-tag></span
      >
      &emsp;&emsp;
      <span
        >抽奖次数：<el-tag type="warning">{{ drawNumber }}</el-tag></span
      >
    </div>
    <div class="cus-table">
      <div class="table_cell">
        <div class="cell bg-grey">名称</div>
        <div class="cell">已抽中奖次数</div>
        <div class="cell">剩余中奖次数</div>
      </div>
      <div class="table_cell">
        <div class="cell bg-grey">总计</div>
        <div class="cell">{{ winPrizeTotal }}</div>
        <div class="cell">{{ surplusPrizeTotal }}</div>
      </div>
      <div class="table_cell" v-for="(item, index) in prizeData" :key="index">
        <div class="cell bg-grey">{{ item.prize_name }}</div>
        <div class="cell">{{ item.win_number }}</div>
        <div class="cell">{{ item.surplus_number }}</div>
      </div>
    </div>
  </el-card>
</template>

<script>
export default {
  props: {
    pNumber: {
      type: Number,
      default: 0,
    },
    drawNumber: {
      type: Number,
      default: 0,
    },
    prizeData: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  computed: {
    winPrizeTotal() {
      return this.sumFunc("win_number");
    },
    surplusPrizeTotal() {
      return this.sumFunc("surplus_number");
    },
  },
  data() {
    return {};
  },
  methods: {
    sumFunc(filed) {
      if (this.prizeData.length === 1) {
        return this.prizeData[0][filed];
      } else if (this.prizeData.length > 1) {
        console.log("this.prizeData", this.prizeData);
        const res = this.prizeData.reduce(function (prev, curr) {
          if (typeof prev === "number") {
            return prev + curr[filed];
          }
          return prev[filed] + curr[filed];
        });
        return res;
      }
      return 0;
    },
  },
};
</script>

<style lang="less" scoped>
.cus-table {
  display: flex;
  border: 1px solid rgb(213, 209, 209);
  .table_cell {
    width: 100%;
    .cell {
      min-width: 86px;
      height: 25x;
      line-height: 25px;
      text-align: center;
      border: 1px solid rgb(227, 221, 221);
    }
  }
}
.bg-grey {
  background-color: rgb(215, 210, 210);
}
</style>
