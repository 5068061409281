<template>
  <div class="main">
    <el-select
      v-model="selectValue"
      filterable
      remote
      reserve-keyword
      placeholder="请输入创建人"
      :remote-method="remoteMethod"
      :loading="loading"
      clearable
      @change="handleSelectChange"
    >
      <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
      </el-option>
    </el-select>
    <el-checkbox v-model="checked" @change="handleCheckboxChange">只看自己</el-checkbox>
  </div>
</template>

<script>
import { getCreatorList } from "@/api/common.js";
export default {
  props: {
    value: {
      type: String,
    },
  },
  data() {
    return {
      selectValue: "",
      options: [],
      list: [],
      loading: false,
      checked: false,
    };
  },
  mounted() {
    getCreatorList().then((res) => {
      this.list = res.data.map((item) => {
        return { value: `${item.uuid}`, label: `${item.name}` };
      });
    });
  },
  methods: {
    remoteMethod(query) {
      if (query !== "") {
        this.loading = true;
        setTimeout(() => {
          this.loading = false;
          this.options = this.list.filter((item) => {
            return item.label.toLowerCase().indexOf(query.toLowerCase()) > -1;
          });
        }, 200);
      } else {
        this.options = [];
      }
    },
    handleSelectChange(v) {
      this.checked = false;
      this.$emit("input", v);
    },
    handleCheckboxChange(v) {
      this.selectValue = "";
      v ? this.$emit("input", this.$store.getters.userId) : this.$emit("input", "");
    },
  },
};
</script>

<style lang="less" scoped>
.main {
  display: inline-block;
  vertical-align: middle;
  margin: 0 10px 10px 0;
}
.el-select {
  width: 230px;
}
.el-checkbox:last-of-type {
  margin-left: -110px !important;
  margin-right: 30px !important;
}
</style>
