<template>
  <el-dialog title="专题页基本信息" :visible.sync="dialogVisible">
    <el-form :model="form" :rules="rules" ref="dataForm">
      <el-form-item label="专题名称" prop="name" :label-width="formLabelWidth">
        <div style="width: 360px">
          <el-input v-model="form.name" placeholder="请输入专题名称"></el-input>
        </div>
      </el-form-item>

      <el-form-item label="专题类型" :label-width="formLabelWidth" required>
        <el-radio-group v-model="form.platform">
          <el-radio label="H5">H5</el-radio>
        </el-radio-group>
      </el-form-item>

      <el-form-item label="是否包含抽奖" :label-width="formLabelWidth" required>
        <el-radio-group v-model="form.lucky_draw" :disabled="disabledPrize">
          <el-radio :label="false">否</el-radio>
          <el-radio :label="true">是</el-radio>
        </el-radio-group>
      </el-form-item>

      <el-form-item label="是否配置渠道链接" :label-width="formLabelWidth" required>
        <el-radio-group v-model="form.hasLink" :disabled="disabledPrize">
          <el-radio :label="false">否</el-radio>
          <el-radio :label="true">是</el-radio>
        </el-radio-group>
      </el-form-item>

      <el-form-item
        v-if="form.lucky_draw"
        label="抽奖配置"
        prop="lucky_draw_uuid"
        :label-width="formLabelWidth"
        required
      >
        <el-select
          v-model="form.lucky_draw_uuid"
          class="filter-item w400"
          clearable
          placeholder="请选择抽奖配置"
          :disabled="disabledPrize"
        >
          <el-option
            v-for="item in freshPrize"
            :key="item.uuid"
            :label="item.name"
            :value="item.uuid"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="起止时间" prop="time_range" :label-width="formLabelWidth">
        <el-date-picker
          v-model="form.time_range"
          type="datetimerange"
          range-separator="至"
          start-placeholder="上线日期"
          end-placeholder="下线日期"
          :default-time="defaultTime"
          value-format="yyyy-MM-dd HH:mm:ss"
          :picker-options="pickerOptions"
        >
        </el-date-picker>
        <div class="f-grey f13 lh16 mt10">
          备注：为确保专题模板发布成功，请设置合理的下线时间；专题页过期之后会自动下线，用户无法访问，如需重新上线，请修改时间后重新发布。
        </div>
      </el-form-item>

      <el-form-item label="活动备注" prop="memo" :label-width="formLabelWidth">
        <div style="width: 360px">
          <el-input v-model="form.memo" type="textarea" placeholder="请输入活动备注"></el-input>
        </div>
      </el-form-item>

      <el-form-item style="text-align: center">
        <el-button type="primary" @click="submit">保 存</el-button>
        <el-button @click="dialogVisible = false">取 消</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
import { addActivity, editActivity, getActivityDetail } from "@/api/activity.js";
import { getLuckDrawFresh } from "@/api/luckDraw";
export default {
  computed: {
    pickerOptions() {
      let now = new Date();
      let lastDay = now.getTime() - 86400000;
      return {
        disabledDate: function (e) {
          if (e.getTime() > lastDay) {
            return false;
          }
          return true;
        },
      };
    },
    disabledPrize() {
      return this.activity_uuid && this.hasPrize;
    },
  },
  watch: {
    "form.lucky_draw": {
      handler(val) {
        if (!val) {
          this.form.lucky_draw_uuid = "";
        }
      },
    },
  },
  data() {
    return {
      activity_uuid: "",
      formLabelWidth: "135px",
      defaultTime: ["00:00:00", "23:59:59"],
      dialogVisible: false,
      form: this.initData(),
      freshPrize: [],
      hasPrize: false,
      rules: {
        structure: [{ required: true, message: "请选择所属架构", trigger: "change" }],
        name: [{ required: true, message: "请输入专题名称", trigger: "change" }],
        time_range: [{ required: true, message: "请选择起止时间", trigger: "change" }],
        lucky_draw_uuid: [
          {
            validator: (rule, value, callback) => {
              if (this.form.lucky_draw) {
                if (value === "") {
                  callback(new Error("请选择抽奖配置"));
                }
              }
              callback();
            },
            trigger: "change",
          },
        ],
      },
    };
  },
  created() {},
  methods: {
    initData() {
      return {
        name: "",
        platform: "H5",
        time_range: [],
        start_time: "",
        end_time: "",
        memo: "",
        lucky_draw: false,
        hasLink: false,
        lucky_draw_uuid: "",
      };
    },
    async handleEdit(v) {
      this.activity_uuid = v;
      this.form = this.initData();
      if (this.activity_uuid) {
        const res = await getActivityDetail(this.activity_uuid);
        this.hasPrize = res.data.lucky_draw;
        this.form = {
          name: res.data.name,
          platform: res.data.platform,
          time_range: [res.data.start_time, res.data.end_time],
          start_time: res.data.start_time,
          end_time: res.data.end_time,
          memo: res.data.memo,
          lucky_draw: res.data.lucky_draw,
          hasLink: res.data.hasLink || false,
          lucky_draw_uuid: res.data.lucky_draw_uuid,
        };
      }
      this.fetchCommonData();
      this.$nextTick(() => {
        this.$refs["dataForm"].clearValidate();
      });
      this.dialogVisible = true;
    },
    submit() {
      this.$refs["dataForm"].validate(async (valid) => {
        if (valid) {
          console.log("this.form:666 ", this.form);
          let req = JSON.parse(JSON.stringify(this.form));
          console.log("req:777 ", req);
          req.start_time = this.form.time_range[0];
          req.end_time = this.form.time_range[1];
          delete req.time_range;
          if (this.activity_uuid) {
            await editActivity(this.activity_uuid, req);
          } else {
            await addActivity(req);
          }
          this.$parent.fetchData();
          this.dialogVisible = false;
          this.$parent.$message({
            message: this.activity_uuid ? "修改活动信息成功" : "添加活动信息成功",
            type: "success",
          });
        }
      });
    },
    async fetchCommonData() {
      const params = {
        is_used: null,
        is_active: null,
      };
      if (!this.activity_uuid) {
        params.is_used = false;
        params.is_active = true;
      } else {
        if (!this.form.lucky_draw) {
          params.is_used = false;
          params.is_active = true;
        }
      }
      const freshResp = await getLuckDrawFresh(params);
      this.freshPrize = freshResp.data;
    },
  },
};
</script>

<style lang="less" scoped>
@import url("@/styles/form.less");
</style>
